import React from 'react';
import { connect } from 'react-redux';
import { className } from '../../../../../lib/className';
import { formatter } from '../../../../../misc/datetime';
import { designateEmployee } from '../../../../../redux/api/assets/employees/reducer';
import DatetimeField from '../../../../general/datetime/DatetimeField';
import Address from '../../../../general/location/Address';

/**
 * @param {Object} props
 * @param {cx.ods.assets.AssetTrip} props.assetTrip
 * @param {number} props.index
 * @param {function} props.onClick
 * @param {boolean} [props.selected]
 */

function ListItem(props) {
	const designation = props.employee ? designateEmployee(props.employee) : '#' + props.assetTrip.assetId;
	const employeeId = props.employee?.externalId || '-';

	const onClick = () => {
		props.onClick(props.assetTrip);
	}

	const _className = className('clickable', { 'selected': props.selected });
	return (
		<>
			<div className={_className} onClick={onClick}><span title={employeeId}>{employeeId}</span></div>
			<div className={_className} onClick={onClick}><span title={designation}>{designation}</span></div>
			<div className={_className} onClick={onClick}>{props.assetTrip.rfid}</div>
			<div className={_className} onClick={onClick}><DatetimeField datetime={props.assetTrip.startedAt} /></div>
			<div className={_className} onClick={onClick}>{props.assetTrip.start && <Address message={props.assetTrip.start} full />}</div>
			<div className={_className} onClick={onClick}>{props.assetTrip.endedAt && <DatetimeField datetime={props.assetTrip.endedAt} />}</div>
			<div className={_className} onClick={onClick}>{props.assetTrip.end && <Address message={props.assetTrip.end} full />}</div>
			<div className={_className} onClick={onClick}>
				{props.assetTrip.endedAt && formatter.duration(props.assetTrip.startedAt, props.assetTrip.endedAt)}
			</div>
			<div className={_className} onClick={onClick}>{props.assetTrip.distance && props.assetTrip.distance / 1000}</div>
		</>
	);
}

export default connect((state, props) => ({ employee: state.assets.employees.map?.[props.assetTrip.assetId] }))(ListItem);
