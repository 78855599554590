import React from 'react';
import { useI18n } from '../../../../../../i18n';

export default function HeaderListItem() {
	const { f } = useI18n();
	return (
		<div className="section header-section">
			<div className="header-item"><span className="capitalize">{f('external id')}</span></div>
			<div className="header-item"><span className="capitalize">{f('designation')}</span></div>
			<div className="header-item"><span className="capitalize">{f('RFID')}</span></div>
			<div className="header-item"><span className="capitalize">{f('start time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('start location')}</span></div>
			<div className="header-item"><span className="capitalize">{f('end time')}</span></div>
			<div className="header-item"><span className="capitalize">{f('end location')}</span></div>
			<div className="header-item"><span className="capitalize">{f('duration')}</span></div>
			<div className="header-item"><span className="capitalize">{f('distance') + ', ' + f('units.km')}</span></div>
		</div>
	);
}
